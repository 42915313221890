import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "../views/Home.vue";
import _fun from '@/helpers/fun';
import _constapp from "@/helpers/const-es";


import { children as general } from "@/router//general.js";
import { children as security } from "@/router//security.js";
import { children as collection } from "@/router//collection.js";
import { children as qryconfiguration } from "@/router//querymanager.js";
import { children as accounting } from "@/router//accounting.js";

Vue.use(VueRouter, {
    scrollBehavior() {
        return window.scrollTo({ top: 0, behavior: 'smooth' });
    },

});
let children = [];
children.push(...general);
children.push(...security);
children.push(...collection);
children.push(...qryconfiguration);
children.push(...accounting);


const routes = [
    {
        path: '*',
        component: () => import('@/views/authentication/Error.vue')
    },
    /* {
        path: "*",
        redirect: "/login",
        
    }, */
    {
        path: "/",
        name: "HOME",
        component: Home,
        meta: {
            authenticated: true,
            breadcrumb: [{ text: "Inicio", disabled: false, href: "/#/" }],
        },
        children: children,
    },
    {
        path: "/micuenta",
        name: "MICUENTA",
        /* breadcrumb: [{
            text: "micuenta",
            disabled: false,
            href: "micuenta",
        }, ], */
        component: () =>
            import ("../views/Auth/Login.vue"),
    },

];


const router = new VueRouter({
    mode: "history",
    routes,
});




router.beforeEach((to, from, next) => {

    let userMenu = JSON.parse(localStorage.getItem("UserOptions") || "{}");
    let activeSession = JSON.parse(localStorage.getItem("UsrID") || "0") > 0 ? true:false;

    var params = _fun.searchObject(userMenu == null ? [] : userMenu, to.name)
    let authenticated = to.matched.some((record) => record.meta.authenticated);
    const token = localStorage.getItem("token");

    if (to.matched.some(record => record.meta.authenticated) ) {
        
        if (token == null) {
            next("/micuenta")
        } 
    } 

    if(to.name !== undefined){
        
        if(to.name !== "HOME" && to.name !== "MICUENTA" ){
            
            if (params !== null) {
                to.params.security = params;
                if (authenticated && !activeSession) next("/micuenta");
                else if (!authenticated && activeSession) next("");
                else next();
            }else{
                to.matched.some((record) => {
                    if(!record.meta.authenticated && record.name !== "HOME"){
                        next();
                    }else{
                        if(record.name !== "HOME"){
                            _fun.sweetAlert("No tiene permisos para esta opción.","warning")
                            next("/"); 
                        }
                    }
                    
                })
            }
        } else if (authenticated && !activeSession){
            next("/micuenta");
        } 

        else if (!authenticated && activeSession) {
            next("/");
        } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
            
            next();
        } else {   
           
            next();
        }

    }else{
        
        next()
    }

    /* next(); */
/*     let userMenu = JSON.parse(localStorage.getItem("UserOptions"));
    let usrExtern = JSON.parse(localStorage.getItem("UsrExtern")) || true


    let activeSession = localStorage.getItem("UsrID") != null ? true : false;
    var params = _fun.searchObject(userMenu == null ? [] : userMenu, to.name)

    let authenticated = to.matched.some((record) => record.meta.authenticated);
    const token = localStorage.getItem("token");


    if (to.matched.some(record => record.meta.authenticated) ) {
        if (token == null) {
            next(_constapp.baseHomeURL)
        } 
    } 

    if (to.name.toUpperCase() != "HOME" && to.name.toUpperCase() != "MICUENTA" && to.name.toUpperCase() != "LOGIN" && to.name.toUpperCase() != "VALIDATE_ACCOUNT" && to.name.toUpperCase() != "RECOVERY_ACCOUNT" && to.name.toUpperCase() != "MARATHON" ) {
       
        if (params !== null) {

            to.params.security = params;

            if (authenticated && !activeSession) next(_constapp.baseHomeURL);
            else if (!authenticated && activeSession) next("");
            else next();
        } 
    } else if (authenticated && !activeSession) next(_constapp.baseHomeURL);

    else if (!authenticated && activeSession) {
        next("");
    } else if (to.name.toUpperCase() == "HOME" && localStorage.getItem("UsrID") != null) {
        next();
    } else {   
        next();
    } */
});

export default router;