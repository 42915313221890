<template>
<div>
    <vc-loading :processing="processing" :errors="errors" @close="processing=false;" />

    <div class="main-card mb-3 card">

        <div class="card-header">
            <i class="header-icon fa-regular fa-credit-card icon-gradient bg-plum-plate"></i>Enviar email
            <v-spacer />
            <!--   <v-btn elevation="0" class="capitalize ml-2 body-1 font-weight-bold" text color="info" @click="save">
                Guardar
            </v-btn>
            <v-btn elevation="0" class="capitalize ml-0 body-1 font-weight-bold" text color="error" @click="$emit('close')">
                Cerrar
            </v-btn> -->
        </div>

        <div class="card-body ml-0" style="max-height: 550px;  overflow-y: auto;">

            <form id="mail">
                <div class="widget-content-outer">
                    <v-row>
                        <v-col cols="12" class="s-col-form">
                            <vc-text id="maiTo" label="Para:" autofocus v-model="mail.maiTo"></vc-text>
                        </v-col>
                        <v-col cols="12" class="s-col-form">
                            <vc-text id="maiSubject" label="Asunto:" v-model="mail.maiSubject"></vc-text>
                        </v-col>

                        <v-col cols="12" class="s-col-form">
                            <v-radio-group v-model="type" class="mt-2">
                                <v-radio value="2" style="margin-top: -10px">
                                    <template v-slot:label>
                                        <div><strong class="primary--text">Generico</strong></div>
                                    </template>
                                </v-radio>
                            </v-radio-group>
                        </v-col>

                        <!-- <div v-show="type == '2'"> -->
                        <v-col cols="12" class="s-col-form">
                            <quill-editor id="maiBody" ref="myQuillEditor" :options="editorOption" v-model="mail.maiBody"></quill-editor>
                        </v-col>
                        <!-- </div> -->
                    </v-row>
                </div>
            </form>
        </div>

        <vc-footer save close @save="save" @close="$emit('close')"></vc-footer>
    </div>
</div>
</template>

<script>
import _sMail from "@/services/Security/SecEmailService.js";
import _validaERP from '@/helpers/validaERP';

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import {
    quillEditor
} from "vue-quill-editor";

export default {
    components: {
        quillEditor

    },
    name: '',
    props: {
        mail: {
            default: {
                maiTo: "",
                maiSubject: "",
                maiBody: ""
            }
            // maiBody: "<br>.........<br>" + this.$fun.getUserFullName() + "<br>" + this.$fun.getUserInfo().GenWorkerCE.PstName + "<br>www.imperu.com.pe"
        }
    },

    data() {
        return {
            //Editor de texto
            editorOption: {
                // some quill options
            },
            type: '2',

            //Loading
            processing: false,
            errors: null,

        }
    },

    created() {

    },
    watch: {

    },

    methods: {

        save() {

            //Validamos Campos
            var resp = _validaERP.valida({
                form: '#mail',
                items: {
                    maiTo: {
                        required: true,
                        email: true,
                        value: this.mail.maiTo,
                    },
                    maiSubject: {
                        required: true,
                        value: this.mail.maiSubject,
                    },

                    maiBody: {
                        required: this.type == '1' ? false : true,
                        value: this.mail.maiBody,
                    },
                }
            })

            if (resp) {

                this.mail.cpyID = this.$fun.getCompanyID()
                this.mail.usrCreateID = this.$fun.getUserID()

                this.$fun.sweetAlert("¿Seguro de enviar Correo a: [" + this.mail.maiTo + "] ?", "question").then(val => {
                    if (val.value) {
                        this.processing = true
                        _sMail.save(this.mail).then(resp => {
                                if (resp.status == 200) {
                                    this.mail = {}
                                    this.processing = false
                                    this.$emit("mail", resp.data.data)
                                    this.$emit('close')
                                }

                            },
                            (e) => {
                                this.errors = e.response
                                this.processing = false;
                            })

                    }
                })

            }
        }
    },
};
</script>
