<template>
  <div>
    <v-label>
      <b>{{ label }}</b>
    </v-label>
    <v-select
      v-if="!autocomplete"
      :return-object="returnObject"
      hide-details
      :ref="'sgeneric' + label"
      placeholder="Seleccione opción"
      :loading="loading"
      :items="items"
      :item-text="itemtitle"
      :item-value="itemvalue"
      @input="input"
      @change="change"
      type="text"
      :clearable="clearable"
      :value="valueLocal"
      :outlined="outlined"
      :autofocus="autofocus"
      dense
      :disabled="disabled"
      :readonly="readonly"
    >
    </v-select>

    <v-autocomplete
      clearable=""
      :ref="'sgeneric' + label"
      v-if="autocomplete && !full"
      :return-object="returnObject"
      :item-value="itemvalue"
      :item-text="itemtitle"
      :items="items"
      :disabled="disabled"
      :readonly="readonly"
      no-filter
      :search-input.sync="search"
      auto-select-first
      hide-details
      :outlined="outlined"
      dense
      @click:clear="items = []"
      :value="valueLocal"
      @input="input($event)"
      @change="change($event)"
      placeholder="Digite opción"
      :autofocus="autofocus"
    >
    </v-autocomplete>

    <v-autocomplete
      v-if="autocomplete && full"
      :clearable="clearable"
      :return-object="returnObject"
      :items="items"
      :disabled="disabled"
      :readonly="readonly"
      :item-text="itemtitle"
      :value="valueLocal"
      :ref="'sgeneric' + label"
      @input="input($event)"
      :item-value="itemvalue"
      placeholder="Seleccione opción"
      hide-details
      :outlined="outlined"
      dense
      :autofocus="autofocus"
    >
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    value: null,
    id: {
      type: String,
      default: "",
    },
    itemtitle: {
      type: String,
      default: "",
    },
    itemvalue: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "v-select",
    },
    config: {
      type: Object,
      default: null,
    },
    noDefault: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      loading: false,
      valueLocal: null,
      search: "",
    };
  },
  watch: {
    config: {
      handler() {
        this.valueLocal = 0;
        this.load();
      },
      deep: true,
    },
    valueLocal() {
      this.$emit("input", this.valueLocal);
    },
    value() {
      this.valueLocal = this.value;
      this.$emit("input", this.valueLocal);
    },

    search() {
      if (this.search) {
        /* if (this.search != this.text) {
                    this.textNull = true;
                } */
        if (this.search.length < 3) this.items = [];
        else this.debouncedGetAnswer();
      }
    },
  },

  created() {
    this.$nextTick().then(() => {
      this.valueLocal = this.value;
      this.$emit("input", this.valueLocal);
    });

    this.debouncedGetAnswer = _.debounce(this.getAnswer, 500);

    this.load();
  },
  mounted() {},
  methods: {
    load() {
      if (this.autocomplete && !this.full) {
      } else {
        if (this.config != null) {
          this.loading = true;
          this.$http
            .get(
              this.config.url,

              {
                params: this.config.params,
              }
            )
            .then((r) => {
              if (r.status == 200) {
                this.items = r.data.data;
                this.loading = false;
              }
            });
        }
      }
    },

    getAnswer(first) {
      if (!this.full)
        if (this.search != null)
          if (this.search.length > 2)
            if (this.autocomplete) {
              if (this.config != null) {
                this.loading = true;
                if (this.config.params != null) {
                  this.config.params.search = this.search;
                }

                this.$http
                  .get(
                    this.config.url,

                    {
                      params: this.config.params,
                    }
                  )
                  .then((r) => {
                    if (r.status == 200) {
                      this.items = r.data.data;
                      this.loading = false;
                    }
                  });
              }
            }
    },

    input(value) {
      //  if(value) this.items=[];
      this.$emit("input", value);
    },
    change(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style></style>
