let children = [];

children.push({
    path: "/querymanager/configuracion",
    name: "QRY_CONFIGURATION",
    component: () =>
        import ("../views/QueryManager/qry_configuration/QryConfiguration.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Configuración", href: "/querymanager/configuracion" },
        ],
    },
});

children.push({
    path: "/querymanager/queryshow",
    name: "QRY_QUERYSHOW",
    component: () =>
        import ("../views/QueryManager/qry_show/QryShow.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Query Show", href: "/querymanager/queryshow" },
        ],
    },
});

children.push({
    path: "/querymanager/reportesusuario",
    name: "QRY_USER_REPORT_PERMITION",
    component: () =>
        import ("../views/QueryManager/qry_configuration_userpermition/QryConfigurationUserPermition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Query Manager", disabled: true },
            { text: "Reporte de Usuarios", href: "/querymanager/reportesusuario" },
        ],
    },
});






export { children };