import Service from "../Service";
import Vue from "vue";
const resource = "SecUser/";

const baseURL = Service.defaults.baseURL + resource;


export default {

    pagination(request) {
        return Service.post(resource + "pagination", request, {
            params: {},
        });
    },

/*     byUser(email) {
        return Service.get(resource + "by-email", {
            params: {email},
        });
    }, */

    save(request) {
        return Service.post(resource + "save", request, {
            params: {},
        });
    },

/*     update(request) {
        return Service.put(resource + "update", request, {
            params: {},
        });
    }, */

    delete(request) {
        return Service.delete(resource + "delete", {
            data: request
        });
    },

    changePassword(request) {
        return Service.post(resource + "change-password", request, {
            params: {},
        });
    },

    getUrlSearchUser() {
        return baseURL + "list";
    },
};