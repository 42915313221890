<template>
<div class="header-dots">

    <!-- <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-xl" variant="link" no-caret right>
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-primary"></div>
                <i class="lnr-menu text-primary"></i>
            </div>
        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">{{'UsrName'}}</h5>
                </div>
            </div>
        </div>
        <div class="scroll-area-xs" style="height: 350px;">
            <ul class="nav flex-column">
                <li class="nav-item-header nav-item">Diseño</li>

                <li class="nav-item">
                </li>
                <v-card max-height="400" style="overflow-x:hidden">
                    <v-card-text>
                        <v-row style="margin-top : -25px">
                            <v-col class="s-col-form">
                                <h6>Lenguage Alertas</h6>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top : -20px">
                            <v-col class="s-col-form">
                                <h6>Alertas</h6>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="s-col-form">
                                <h6>Alertas Posiciòn</h6>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </ul>

        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>

        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <bc-button class="mt-4" variant="gradient" style="background:#3364b7" color="info" fullWidth size="lg">Guardar</bc-button>
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown> -->
    <!--     <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" style="" variant="link" no-caret left>
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-danger"></div>
                <i class="lnr-bullhorn text-danger ml-2"></i>
                <div class="badge badge-dot badge-dot-sm badge-danger"></div>
            </div>
        </span>
    </b-dropdown> -->



    <b-dropdown toggle-class="p-0 mr-2" menu-class="dropdown-menu-end dropdown-menu-xl" variant="link" no-caret right style="margin-top:-10px">
        <span slot="button-content">
            <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                <!-- <img width="38" class="rounded-circle" src="../../../assets/images/User.png" alt /> -->
                <v-avatar size="42">
                    <!--  <img :src="$fun.getUserInfo().worker == null ? '@/assets/images/User.png' : $fun.getUserInfo().worker.wrkUriPhoto" width="80" /> -->
                    <img v-if="$fun.getUserInfo().worker == null" src="@/assets/images/User.png" width="80" />
                    <img v-else :src="$fun.getUserInfo().worker.wrkUriPhoto" width="80" />
                </v-avatar>
            </div>

        </span>
        <div class="dropdown-menu-header mb-0">
            <div class="dropdown-menu-header-inner bg-blue">
                <div class="menu-header-image opacity-1 dd-header-bg-2"></div>
                <div class="menu-header-content text-dark">
                    <v-avatar size="88">
                        <!-- <img :src="$fun.getUserInfo().worker == null ? '@/assets/images/User.png' : $fun.getUserInfo().worker.wrkUriPhoto" width="80" /> -->
                        <img v-if="$fun.getUserInfo().worker == null" src="@/assets/images/User.png" width="80" />
                        <img v-else :src="$fun.getUserInfo().worker.wrkUriPhoto" width="80" />
                    </v-avatar>
                    <h5 class="menu-header-title mt-3">{{$fun.getUserFullName() ?? $fun.getUserName()}}</h5>
                    <div class="widget-subheading">{{$fun.getAreName() ?? 'Sin Área asignada'}}</div>
                    <div class="widget-subheading">{{$fun.getEmail() ?? 'Sin Correo' }}</div>
                </div>
            </div>
        </div>
        <div class="scroll-area-xs" style="height: 350px;">
            <ul class="nav flex-column">
                <li class="nav-item-header nav-item">Menú</li>

                <li class="nav-item">
                </li>
                <li class="nav-item">
                    <!-- <a style="color: #1976d2;" class="nav-link">Cambiar Password</a> -->
                </li>

                <li class="nav-item">
                </li>

            </ul>

        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>

        <div class="grid-menu grid-menu-2col overflow-hidden pl-3 pr-3">
            <v-btn small block elevation="0" @click="$emit('logout')">Cerrar sesión</v-btn>
            <!-- <bc-button class="mt-4" variant="gradient"  color="info" fullWidth size="lg">Cerrar sesión</bc-button> -->
        </div>

        <ul class="nav flex-column">
            <li class="nav-item-divider mb-0 nav-item"></li>
        </ul>
    </b-dropdown>
</div>
</template>

<script>
//Services
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import CountryFlag from "vue-country-flag";
//Components

import {
    library
} from "@fortawesome/fontawesome-svg-core";
import {
    faArrowUp,
    faCog
} from "@fortawesome/free-solid-svg-icons";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";

library.add(faArrowUp, faCog);

export default {
    components: {
        VuePerfectScrollbar,
        CountryFlag,
        "font-awesome-icon": FontAwesomeIcon,
    },
    props: {

    },
    data: () => ({

        fill1: {
            gradient: ["#00b09b", "#96c93d"]
        },
        fill2: {
            gradient: ["#ff0844", "#ffb199"]
        },
        fill3: {
            gradient: ["#f6d365", "#fda085"]
        },
        showDrawerSection: false
    }),
    created() {

    },
    methods: {

    }
};
</script>
