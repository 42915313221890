let children = [];

children.push({
    path: "/configuracion/opcion",
    name: "SEC_OPTION",
    component: () =>
        import ("../views/Security/Option/OptOption.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Opción", href: "/configuracion/opcion" },
        ],
    },
});

children.push({
    path: "/configuracion/usuarios",
    name: "SEC_USER",
    component: () =>
        import ("../views/Security/UserOption/UserOption.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", disabled: true },
            { text: "Usuarios", href: "/configuracion/usuarios" },
        ],
    },
});

children.push({
    path: "/configuracion/cuenta",
    name: "SEC_ACCOUNT",
    component: () =>
        import ("@/views/Auth/AccountSettings.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Seguridad", href:"/", disabled: true },
            { text: "Mi Cuenta", href: "/configuracion/cuenta", disabled:false },
        ],
    },
});

export { children };