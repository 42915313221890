import Service from "../Service";

const resource = "GenCompany/";
const baseURL = Service.defaults.baseURL + resource;

export default {

    pagination(request) {
        return Service.post(resource + "pagination", request, {
            params: {},
        });
    },

    save(request) {
        return Service.post(resource + "save", request, {
            params: {}
        })
    },

    update(request) {
        return Service.put(resource + "update", request, {
            params: {},
        });
    },

    delete(request) {
        return Service.delete(resource + "delete", {
            data: request
        });
    },

    getUrlSearchCompany() {
        return baseURL + "list";
    },

}