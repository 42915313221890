<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic
      style="margin-top: -1px; margin-left: -16px"
      itemtitle="usrName"
      itemvalue="usrID"
      id="usrID"
      :return-object="returnObject"
      :config="this.config"
      :value="value"
      @input="input"
      @change="change"
      :outlined="outlined"
      :label="label"
      :full="full"
      :autocomplete="autocomplete"
    >
    </vc-select-generic>
  </div>
</template>

<script>
import _sUser from "@/services/Security/SecUserService";
export default {
  name: "",
  components: {},
  props: {
    value: null,
    label: {
      type: String,
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    areID: {
      type: Number,
      default: 0,
    },
         autocomplete: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    input(item) {
      this.$emit("input", item);
    },
    change(item) {
      this.$emit("change", item);
    },
  },
  data() {
    return {
      valueLocal: null,
      config: {
        url: _sUser.getUrlSearchUser(),
        title: this.label,
        params: {
          areID: this.areID,
        },
      },
    };
  },

  created() {
    this.config = {
      url: _sUser.getUrlSearchUser(),
      title: this.label,
      params: {
        areID: this.areID,
      },
    };
  },
};
</script>
