<template>
  <div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic
      style="margin-top: -1px; margin-left: -16px"
      :clearable="clearable"
      :autocomplete="autocomplete"
      :disabled="disabled"
      :full="full"
      itemtitle="areName"
      itemvalue="areID"
      id="areID"
      :return-object="returnObject"
      :config="this.config"
      :value="value"
      @input="input"
      @change="change"
      :outlined="outlined"
      :label="label"
    >
    </vc-select-generic>
  </div>
</template>

<script>
import _sUser from "@/services/General/GenGenericService";
export default {
  name: "",
  components: {},
  props: {
    value: null,
    label: {
      type: String,
      default: "Áreas",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    input(item) {
      this.$emit("input", item);
    },
    change(item) {
      this.$emit("change", item);
    },
  },
  data() {
    return {
      valueLocal: null,
      config: {
        url: _sUser.getUrlSearchArea(),
        title: this.label,
        params: {},
      },
    };
  },

  created() {
    this.config = {
      url: _sUser.getUrlSearchArea(),
      title: this.label,
      params: {},
    };
  },
};
</script>
