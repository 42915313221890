let children = [];
children.push({
    path: "/cobranzas/tramos",
    name: "COB_SECTIONS",
    component: () =>
        import ("../views/Collection/cob_sections/CobSections.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Tramos", href: "/cobranzas/tramos" },
        ],
    },
});

children.push({
    path: "/cobranzas/segmentos",
    name: "COB_SEGMENT",
    component: () =>
        import ("../views/Collection/cob_segment/CobSegment.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Segmentos", href: "/cobranzas/segmentos" },
        ],
    },
});


children.push({
    path: "/cobranzas/asignarcartera",
    name: "COB_ASSIGN_BRIEFCASE",
    component: () =>
        import ("../views/Collection/cob_bbriefcase_assign/CobBbriefcaseAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Cartera", href: "/cobranzas/asignarcartera" },
        ],
    },
});


children.push({
    path: "/cobranzas/asignartramos",
    name: "COB_ASSIGN_SECTION",
    component: () =>
        import ("../views/Collection/cob_section_assign/CobSectionAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Tramos", href: "/cobranzas/asignartramos" },
        ],
    },
});


children.push({
    path: "/cobranzas/asignarsegmentos",
    name: "COB_ASSIGN_SEGMENT",
    component: () =>
        import ("../views/Collection/cob_segment_assign/CobSegmentAssign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Asignar Segmentos", href: "/cobranzas/asignarsegmentos" },
        ],
    },
});

children.push({
    path: "/cobranzas/campanias",
    name: "COB_CAMPAIGN",
    component: () =>
        import ("../views/Collection/cob_campaign/CobCampaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Campañas", href: "/cobranzas/campanias" },
        ],
    },
});

children.push({
    path: "/cobranzas/ignorarclientes",
    name: "COB_CUSTOMER_IGNORED",
    component: () =>
        import ("../views/Collection/cob_customer_ignore/CobCustomerIgnore.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Ingorar Clientes", href: "/cobranzas/ignorarclientes" },
        ],
    },
});

children.push({
    path: "/cobranzas/cargarcampanias",
    name: "COB_LOAD_CAMPAIGN",
    component: () =>
        import ("../views/Collection/cob_customer_campaign/CobCustomerCampaign.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Cargar Campañas", href: "/cobranzas/cargarcampanias" },
        ],
    },
});

children.push({
    path: "/cobranzas/menu",
    name: "COB_MANAGEMENT",
    component: () =>
        import ("../views/Collection/cob_menu/CobIndex.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Menú", href: "/cobranzas/menu" },
        ],
    },
});

children.push({
    path: "/cobranzas/tareas",
    name: "COB_TASK",
    component: () =>
        import ("../views/Collection/cob_task/CobTaskPaginated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Tareas", href: "/cobranzas/tareas" },
        ],
    },
});

children.push({
    path: "/cobranzas/reportes",
    name: "COB_REPORT_GRAF",
    component: () =>
        import ("../views/Collection/cob_report/CobReportMain.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Reportes", href: "/cobranzas/reportes" },
        ],
    },
});

children.push({
    path: "/cobranzas/coordenadas",
    name: "COB_CUSTOMER_COORDINATES",
    component: () =>
        import ("../views/Collection/cob_customer_coordinate/CobCustomerCoordinate.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Coordenadas", href: "/cobranzas/coordenadas" },
        ],
    },
});

children.push({
    path: "/cobranzas/ruteocobranzas",
    name: "COB_ROUTING_COLLECTION",
    component: () =>
        import ("../views/Collection/cob_customer_coordinate/CobRoutingCollection.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Ruteo Cobranzas", href: "/cobranzas/ruteocobranzas" },
        ],
    },
});

children.push({
    path: "/cobranzas/plantillaswhatsapp",
    name: "COB_TEMPLATE_WHASTAPP",
    component: () =>
        import ("../views/Collection/cob_template_whatsapp/CobTemplateWhatsapp.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Plantillas WhatsApp", href: "/cobranzas/plantillaswhatsapp" },
        ],
    },
});

children.push({
    path: "/cobranzas/plantillascorreo",
    name: "COB_TEMPLATE_MAIL",
    component: () =>
        import ("../views/Collection/cob_template_mail/CobTemplateMail.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Plantillas Correo", href: "/cobranzas/plantillascorreo" },
        ],
    },
});

children.push({
    path: "/cobranzas/nivelriesgo",
    name: "COB_RISK_LEVEL",
    component: () =>
        import ("../views/Collection/cob_customer_risklevel/CobRiskLevel.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Nivel de Riesgo", href: "/cobranzas/nivelriesgo" },
        ],
    },
});

children.push({
    path: "/cobranzas/analisisrecupero",
    name: "COB_RECOVERY_ANALYSIS",
    component: () =>
        import ("../views/Collection/cob_report/CobReportManagementRecovered.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Analisis de Recupero", href: "/cobranzas/analisisrecupero" },
        ],
    },
});


children.push({
    path: "/cobranzas/analisistramo",
    name: "COB_ANALYSIS_SECTION",
    component: () =>
        import ("../views/Collection/cob_report/CobReportDuesSection.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Analisis por Tramo", href: "/cobranzas/analisistramo" },
        ],
    },
});



children.push({
    path: "/cobranzas/whatsappprogramados",
    name: "COB_WHATSAPP_PROGRAM",
    component: () =>
        import ("../views/Collection/cob_whatsapp_program/CobWhatsappProgramPaginated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "WhatsApp Programados", href: "/cobranzas/whatsappprogramados" },
        ],
    },
});

children.push({
    path: "/cobranzas/statuswhatsap",
    name: "COB_WHATSAPP_STATUS",
    component: () =>
        import ("../views/Collection/cob_whatsapp_status/CobWhatsappStatus.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Status WhatsApp", href: "/cobranzas/statuswhatsap" },
        ],
    },
});

children.push({
    path: "/cobranzas/correosprogramados",
    name: "COB_MAIL_PROGRAM",
    component: () =>
        import ("../views/Collection/cob_mail_program/CobMailProgramPaginated.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Correos Programados", href: "/cobranzas/correosprogramados" },
        ],
    },
});

children.push({
    path: "/cobranzas/statuscorreos",
    name: "COB_MAIL_STATUS",
    component: () =>
        import ("../views/Collection/cob_mail_status/CobMailStatus.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "Cobranzas", disabled: true },
            { text: "Status Correos", href: "/cobranzas/statuscorreos" },
        ],
    },
});








export { children };