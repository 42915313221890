<template>
<div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic style="margin-top: -1px; margin-left: -16px" :clearable="clearable" :autocomplete="autocomplete" :full="full" itemtitle="pstName" itemvalue="pstID" id="pstID" :return-object="returnObject" :config="this.config" :value="value" @input="input" @change="change" :outlined="outlined" :label="label">
    </vc-select-generic>
</div>
</template>

<script>
import _sGeneric from "@/services/General/GenGenericService";
export default {
    name: "",
    components: {},
    props: {
        value: null,
        label: {
            type: String,
            default: "Cargo",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
        areID: 0,
    },

    data() {
        return {
            valueLocal: null,
            config: {
                url: _sGeneric.getUrlSearchPosition(),
                title: this.label,
                params: {},
            },
        };
    },

    created() {
        this.get()
    },

    watch: {
        areID() {
            this.get()
        },
    },

    methods: {
        get() {
            this.config = {
                url: _sGeneric.getUrlSearchPosition(),
                title: this.label,
                params: {
                    areID: this.areID
                },
            };
        },
        input(item) {
            this.$emit("input", item);
        },
        change(item) {
            this.$emit("change", item);
        },
    },

};
</script>
