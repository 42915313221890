import Service from "../Service";
const resource = "SecEmail/";

export default {

    save(request) {
        return Service.post(resource + "save", request, {
            params: {},
        });
    },
};