
export default {

    //Message
    MSG_SAVE: "Seguro de Guardar ?",
    MSG_UPDATE: "Seguro de Actualizar ?.",
    MSG_DELETE: "Seguro de Eliminar ?.",
    MSG_SUCCESS_SAVE: "Agregado Correctamente.",
    MSG_SUCCESS_UPDATE: "Actualizado Correctamente.",
    MSG_SUCCESS_DELETE: "Eliminado Correctamente.",
    MSG_NOT_SERVICE: "No existe servicio",



    //All
    FormatDateDB: "YYYY-MM-DD",
    FormatDateDBNiubiz: "YYYY-MM-DDTHH:mm:ss-0000",
    FormatDateTimeDB: "YYYY-MM-DD HH:mm",
    FormaTimeDB: "YYYY-MM-DD HH:mm",
    FormatDateView: "DD/MM/YYYY",
    FormatDateTimeView: "DD/MM/YYYY HH:mm",
    FormatTimeDB: " HH:mm",
    FormatTimeView: " HH:mm",
    FormatDateMask: "##/##/####",
    FormatDateTimeMask: "##/##/#### ##:##", 
    FormatDateDayMonthDB: "DD.MM",

    //Producción
    baseHomeExtern: "USER",  
    baseHomeURL: "/micuenta",
    baseURL: process.env.VUE_APP_V2_API_BASE_URL,

    TypeFile: {
        EXCEL: { ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", Extension: "xlsx" },
        PDF: { ContentType: "application/pdf", Extension: "pdf" },
        ZIP: { ContentType: "application/pdf", Extension: "zip" },
    },

};
