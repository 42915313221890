<template>
<div style="padding-top: -2px; margin-top: -2px">
    <vc-select-generic style="margin-top: -1px; margin-left: -16px" :clearable="clearable" itemtitle="cpyName" itemvalue="cpyID" id="cpyID" :return-object="returnObject" :config="this.config" :value="valueLocal" @input="input" @change="change" :outlined="outlined" :label="label">
    </vc-select-generic>
</div>
</template>

<script>
import _sUser from "@/services/General/GenCompanyService";
export default {
    name: "",
    components: {},
    props: {
        value: null,
        label: {
            type: String,
            default: "",
        },
        returnObject: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: true,
        },
        clearable: {
            type: Boolean,
            default: false
        },
    },

    methods: {
        input(item) {
            this.$emit("input", item);
        },
        change(item) {
            this.$emit("change", item);
        },
    },
    data() {
        return {
            valueLocal: null,
            config: {
                url: _sUser.getUrlSearchCompany(),
                title: this.label,
                params: {},
            },
        };
    },

    created() {
        this.config = {
            url: _sUser.getUrlSearchCompany(),
            title: this.label,
            params: {},
        };
    },
};
</script>
