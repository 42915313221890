<template>
    <div>
        <v-dialog v-if="showLocal" v-model="showLocal" :persistent="messageResponse.length == 0" width="400">
            <v-row>
                <svg viewBox="0 0 100 100" class="loading" v-if="messageResponse.length == 0">
                    <g fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="6">
                        <!-- left line -->
                        <path d="M 21 40 V 59">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                values="0 21 59; 180 21 59"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </path>
                        <!-- right line -->
                        <path d="M 79 40 V 59">
                            <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                values="0 79 59; -180 79 59"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </path>
                        <!-- top line -->
                        <path d="M 50 21 V 40">
                            <animate attributeName="d" values="M 50 21 V 40; M 50 59 V 40" dur="2s" repeatCount="indefinite" />
                        </path>
                        <!-- btm line -->
                        <path d="M 50 60 V 79">
                            <animate attributeName="d" values="M 50 60 V 79; M 50 98 V 79" dur="2s" repeatCount="indefinite" />
                        </path>
                        <!-- top box -->
                        <path d="M 50 21 L 79 40 L 50 60 L 21 40 Z">
                            <animate
                                attributeName="stroke"
                                values="rgba(255,255,255,1); rgba(100,100,100,0)"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </path>
                        <!-- mid box -->
                        <path d="M 50 40 L 79 59 L 50 79 L 21 59 Z" />
                        <!-- btm box -->
                        <path d="M 50 59 L 79 78 L 50 98 L 21 78 Z">
                            <animate
                                attributeName="stroke"
                                values="rgba(100,100,100,0); rgba(255,255,255,1)"
                                dur="2s"
                                repeatCount="indefinite"
                            />
                        </path>
                        <animateTransform
                            attributeName="transform"
                            attributeType="XML"
                            type="translate"
                            values="0 0; 0 -19"
                            dur="2s"
                            repeatCount="indefinite"
                        />
                    </g>
                </svg>
<!-- 
                <v-card color="white" style="border-radius: 6px; opacity: 0.9" v-else>
                    <v-card-text>
                        <div class="pt-0" style="color: black">
                            <h5>Errores encontrados:</h5>
                            <br />
                            {{ messageResponse }}
                        </div>
                    </v-card-text>
                </v-card> -->
            </v-row>
        </v-dialog>
    </div>
</template>

<script>
import ClipLoader from 'vue-spinner/src/RiseLoader.vue';

export default {
    emits:['close'],
    components: {
        ClipLoader
    },
    props: {
        title: {
            type: String,
            default: 'Procesando'
        },
        processing: {
            type: Boolean,
            default: false
        },
        errors: null
    },
    data: () => ({
        messageResponse: ''
    }),

    created() {
        this.messageResponse = '';
    },

    computed: {
        showLocal: {
            get: function () {
                this.messageResponse = '';
                return this.processing;
            },
            set: function (value) {
                this.$emit('close');
            }
        }
    },

    watch: {
        errors() {
            if (!this.errors) {
                this.$fun.alertFull('No se puede acceder a los datos en este momento. Si el problema persiste, contacta al soporte técnico.', 'error');
                this.showLocal = false;
                return;
            }

            var error = this.errors.data.errors

            switch (this.errors.status) {
                case 400:
                    this.messageResponse = error;
                    break;
                case 401:
                    this.messageResponse = '[No Autorizado]';
                    break;
                case 403:
                    this.messageResponse = '[Solicitud denegada]';
                    break;
                case 404:
                    break;
                case 500:
                    this.messageResponse = '[Connection Failed]';

                    break;
                case 502:
                    break;
            }
        }
    }
};
</script>

<style lang="css">
.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 150px;
    width: 150px;
}
</style>
