let children = [];

children.push({
    path: "/general/catalogos",
    name: "GEN_DEFINITION",
    component: () =>
        import ("../views/General/gen_definition/GenDefinition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Catálogos", href: "/general/catalogos" },
        ],
    },
});

children.push({
    path: "/general/areas",
    name: "GEN_AREA",
    component: () =>
        import ("../views/General/gen_area/GenArea.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Áreas", href: "/general/areas" },
        ],
    },
});

children.push({
    path: "/general/cargos",
    name: "GEN_POSITION",
    component: () =>
        import ("../views/General/gen_position/GenPosition.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Cargos", href: "/general/cargos" },
        ],
    },
});

children.push({
    path: "/general/parametros",
    name: "GEN_PARAMETER",
    component: () =>
        import ("../views/General/gen_parameter/GenParameter.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Parametros", href: "/general/parametros" },
        ],
    },
});

children.push({
    path: "/general/trabajador",
    name: "GEN_WORKER",
    component: () =>
        import ("../views/General/gen_worker/GenWorker.vue"),
    meta: {
        authenticated: true,
        breadcrumb: [
            { text: "Inicio", href: "/#/" },
            { text: "General", disabled: true },
            { text: "Trabajador", href: "/general/trabajador" },
        ],
    },
});




export { children };