import Service from "./Service";
const menu = "Account/";

export default {
    login(request) {
        localStorage.clear();

        return Service.post(menu + "login", request , {
            params: {},
        });
    },

    refreshToken(request) {
        return Service.post(menu + "refresh-token", request , {
            params: {},
        });
    },


};